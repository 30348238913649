<template>
	<b-card header-tag="header">
		<template v-slot:header>
			<product-form-nav link="attribute" :productDetails="productDetails" :productId="id"></product-form-nav>
		</template>
		<div style="max-width:800px">
		<form class="form" novalidate="novalidate" id="product_attr">
			<div v-if="form.attributesInfo.length">
				<b-row v-for="(filter, key) in form.attributesInfo" :key="key">
					<b-col cols="4">
						<b-form-group label-for="input-1">
						<b-form-select v-model="filter.attribute_id" name="attributeId" required :options="attributes" >
										</b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="4">
						<b-form-group label-for="input-1">
							<b-form-input name="attributeVal" v-model="filter.attribute_value" type="text" 
								placeholder="Attribute Value" class="form-control"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="4">
						<button class="btn btn-sm btn-outline-danger mr-2" @click.prevent="deleteFilter(key)">
							<i class="fas fa-trash"></i>
						</button>

						<button v-if="form.attributesInfo.length == key+1" class="btn btn-sm btn-outline-primary"
							@click.prevent="newFilter">
							<i class="fas fa-plus"></i>
						</button>
					</b-col>
				</b-row>
			</div>
			<b-col v-else class="text-right mb-5">
				<button class="btn btn-sm btn-outline-primary" @click.prevent="newFilter">
					<i class="fas fa-plus"></i>New Filter
				</button>
			</b-col>
			<b-row>
				<b-col class="text-right">
							<button class="btn btn-primary" ref="ha_submit">
								<i class="las la-save"></i> Save and Continue
							</button>
					</b-col>
			</b-row>
		</form>
	</div>
	</b-card>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

	// FormValidation plugins
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";

	//api services
	import AttributeService from "@/core/services/api/attribute";
	import ProductService from "@/core/services/api/product/product";

	export default {
		components: {
			ProductFormNav,
		},
		data() {
			return {
				attributeList:[],
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				form: {
					attributesInfo:[{
					attribute_id: null,
					attribute_value: null,
				}]
				},
				productDetails:{}
			};
		},
		computed: {
			attributes: function(){
				var attributeList = this.attributeList;
				var attributes = [{
						value:null,
						text:"Please select an attribute"
					}];
				attributeList.map((e)=>{
					attributes.push( {
						value:e.id,
						text:e.name
					})
				})
				return attributes;
			}
		},
		mounted() {
            this.getAttributes();
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Data",
				},
			]);
			this.getProductById();
			this.initForm();
		},
		methods: {
			initForm(){
				if (this.fv) {
					this.fv.destroy();
				}
				const signin_form = KTUtil.getById("product_attr");
				this.fv = formValidation(signin_form, {
					fields: {
						"attributeVal": {
							validators: {
								notEmpty: {
									message: "Attribute value is required",
								},
								stringLength: {
									min: 1,
									message: 'The Product name must be minimum 1 characters',
								},
							},
						},
						"attributeId": {
							validators: {
								notEmpty: {
									message: "Attribute is required",
								}
							},
						},
					},
					plugins: {
						trigger: new Trigger(),
						submitButton: new SubmitButton(),
						bootstrap: new Bootstrap(),
					},
				});

				this.fv.on("core.form.valid", () => {
					// set spinner to submit button
					const submitButton = this.$refs["ha_submit"];
					submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				
					
					this.updateProduct(function(){
						submitButton.classList.remove(
							"spinner",
							"spinner-light",
							"spinner-right",
							"disabled"
						);
					});

					
				});
			
			},
			async getAttributes() {
				var response = await AttributeService.getAttributes();
				this.attributeList = response.data;
			},
			async newFilter() {
				await this.form.attributesInfo.push({
					attribute_id: null,
					attribute_value: "",
				});
				this.initForm();
			},
			deleteFilter(index) {
				this.form.attributesInfo.splice(index, 1)
			},
			async getProductById() {
				var response = await ProductService.getProductById(this.id);
				this.productDetails = response;
				if(response.attributesInfo.length!=0){
					this.form.attributesInfo = response.attributesInfo;
				}
			},
			updateProduct(callback) {
				var vm = this;
				ProductService.updateProduct(vm.id,vm.form).then(function (response) {
						vm.$router.push({ name: "product-form-link", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
			},
		},
	};
</script>

<style></style>